<template>
  <b-container
    fluid
    class="mt-2 col-12"
    align-h="center"
  >
    <div
      v-if="!addEditMode"
      class="padding-bottom-64"
    >
      <!-- <b-row
        class="mintui__background-f6fafb"
      >
        <b-row
          class="col-md-12"
        >
          <h4
            class="aiops_mineral_green_heading4 padding-top-24 padding-left-32"
          >FILTERS</h4>
        </b-row>
        <b-row
          class="col-md-12 padding-bottom-24"
        >
          <b-col
            class="col-md-3 pl-0"
          >
            <b-form-group
              class="aiops_label_20 padding-left-32"
              label-for="typeIssue"
            >
              <span
                class="aiops_neutral_blue_heading4"
              >
                AREA
              </span>
              <b-form-select
                id="typeIssue"
                v-model="selectedRole"
                :options="itemsRolesFilter"
                @change="filterKnowledgeBase()"
              />
            </b-form-group>
          </b-col>
          <b-col
            class="col-md-3 pl-0"
          >
            <b-form-group
              class="aiops_label_20 padding-left-32"
              label-for="typeIssue"
            >
              <span
                class="aiops_neutral_blue_heading4"
              >
                CATEGORY L2
              </span>
              <b-form-select
                id="typeIssue"
                v-model="selectedStatus"
                :options="itemsStatusFilter"
                @change="filterKnowledgeBase()"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-row> -->
      <b-row
        class="margin-bottom-24 margin-top-24"
      >
        <b-col
          class="col-sm-12 col-md-1 pl-0"
        >
          <div class="aiops__users--button-actions">
            <b-button
              class="ibop-gothic-button"
              @click="addKnowledgeBaseMode"
            >
              <b-img
                class="mintui_list_medium"
              />
              <span class="align-middle aiops_label_14_3e7282 padding-left-4">Add</span>
            </b-button>
          </div>
        </b-col>
        <!-- <b-col
          offset-md="9"
          class="col-sm-12 col-md-2 pr-0"
        >
          <b-input-group
            class="input-group-merge"
          >
            <b-form-input
              v-model="searchValue"
              autocomplete="off"
              type="search"
              placeholder="Search"
              @update="filterKnowledgeBase()"
            />
            <b-input-group-append
              is-text
              class="icon-append"
            >
              <feather-icon icon="SearchIcon" />
            </b-input-group-append>
          </b-input-group>
        </b-col> -->
      </b-row>
      <b-row
        v-if="getKnowledgeBaseList.length > 0"
        class="home-page"
      >
        <b-col md="12">
          <h2 class="aiops_mineral_green_heading2">
            Knowledge base list
          </h2>
          <b-list-group-item
            v-for="(listItem) in getKnowledgeBaseList"
            :key="listItem.idKnowledge"
            tag="li"
          >
            <div class="d-flex">
              <b-avatar :text="listItem.area.slice(0,2)" />
              <div class="ml-25">
                <b-card-text class="mb-0 font-weight-bold">
                  {{ listItem.area }} - {{ listItem.createdDate | formatDateKnowledge }} - {{ listItem.modifiedDate | formatDateKnowledge }}
                </b-card-text>
                <small>{{ listItem.knowledge }}</small>
              </div>
            </div>
            <div class="mt-2">
              <b-button
                class="ibop-salmon-pearl-button mr-1"
                @click="deleteKnowledgeBase(listItem)"
              >
                <span class="align-middle aiops_paragraph_accent-red_bold">Delete</span>
              </b-button>
              <b-button
                class="ibop-mineral-green-button"
                @click="editKnowledBase(listItem)"
              >
                <span class="align-middle aiops_label_white_14">Update</span>
              </b-button>
            </div>
          </b-list-group-item>
          <!-- <draggable
            :list="getKnowledgeBaseList"
            tag="ul"
            group="people"
            class="list-group list-group-flush cursor-move"
          >
          </draggable> -->
        </b-col>
      </b-row>
    </div>
    <div
      v-if="addEditMode"
      class="padding-bottom-64"
    >
      <b-row
        class="padding-bottom-24"
        align-h="between"
      >
        <b-col>
          <h2
            class="aiops_mineral_green_heading2"
          >
            <b-img
              class="mintui_back_green"
              @click="addKnowledgeBaseMode"
            />
            {{ actionForUser }} Knowledge base
          </h2>
        </b-col>
        <b-col
          offset="7"
          class="col-md-2"
        >
          <div class="aiops__users--button-actions-add-role">
            <b-button
              class="ibop-salmon-pearl-button mr-1"
              @click="addKnowledgeBaseMode"
            >
              <span class="align-middle aiops_paragraph_accent-red_bold">Cancel</span>
            </b-button>
            <b-button
              v-if="!editMode"
              class="ibop-mineral-green-button"
              @click="addNewKnowledBase()"
            >
              <span class="align-middle aiops_label_white_14">Save</span>
            </b-button>
            <b-button
              v-if="editMode"
              class="ibop-mineral-green-button"
              @click="updateKnowledgeBase()"
            >
              <span class="align-middle aiops_label_white_14">Save</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row
        class="mintui__background-f6fafb"
      >
        <b-col>
          <validation-observer
            ref="aiopsNewKnowledgeBase"
          >
            <b-form>
              <div
                class="row col-md-12 aiops__roles-role-name"
              >
                <b-col
                  class="col-sm-12 col-md-4 pl-0"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Area"
                    rules="required"
                  >
                    <b-form-group
                      class="aiops_label_20"
                      label-for="group"
                    >
                      <span
                        class="aiops_neutral_blue_heading4"
                      >
                        AREA
                      </span>
                      <b-form-select
                        id="Area"
                        v-model="newKnowledgeBase.area"
                        :options="areaList"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- <b-col
                  class="col-sm-12 col-md-4 pl-0"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="CategoryL2"
                    rules="required"
                  >
                    <b-form-group
                      class="aiops_label_20"
                      label-for="status"
                    >
                      <span
                        class="aiops_neutral_blue_heading4"
                      >
                        CATEGORY L2
                      </span>
                      <b-form-select
                        id="CategoryL2"
                        v-model="newUser.enabled"
                        :options="itemsStatusFilter"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col> -->
                <b-col
                  class="col-sm-12 col-md-12 pl-0"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="knowledgebasetext"
                    rules="required"
                  >
                    <b-form-group
                      class="aiops_label_20"
                      label-for="username"
                    >
                      <span
                        class="aiops_neutral_blue_heading4"
                      >
                        KNOWLEDGE BASE TEXT
                      </span>
                      <b-form-textarea
                        id="username"
                        v-model="newKnowledgeBase.knowledge"
                        style="height: 230px;"
                        autocomplete="off"
                        placeholder="Write knowledge base"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </div>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import {
  BContainer,
  BTable,
  BTr,
  BTh,
  BPagination,
  BButton,
  BRow,
  BCol,
  BSpinner,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BFormSelect,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BImg,
  BListGroupItem,
  BAvatar,
  BCardText,
  BFormTextarea,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import store from '@/store'
import utilsAiops from '@/@core/utils/aiops/utils'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, password } from '@validations'

const {
  loading,
  loadingProccessSpinner,
  setMessage,
  formatDate,
} = utilsAiops()

export default {
  components: {
    BContainer,
    BTable,
    BTr,
    BTh,
    BPagination,
    BButton,
    BRow,
    BCol,
    BSpinner,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BFormSelect,
    BFormCheckbox,
    BFormGroup,
    BForm,
    BImg,
    ValidationProvider,
    ValidationObserver,
    BListGroupItem,
    BAvatar,
    draggable,
    BCardText,
    BFormTextarea,
  },
  filters: {
    filterNullValue(val) {
      const newVal = val || '---'
      return newVal
    },
    filterEnabledValue(val) {
      const newVal = val ? 'Active' : 'Inactive'
      return newVal
    },
    formatDateKnowledge(val) {
      const newVal = val ? formatDate(val) : '---'
      return newVal
    },
  },
  data() {
    return {
      email,
      required,
      password,
      // eslint-disable-next-line global-require
      logo: require('@/assets/images/aiops/logo/business_platform_logo_mini.svg'),
      actionForUser: '',
      addEditMode: false,
      editMode: false,
      selectedRole: null,
      selectedStatus: null,
      currentPage: 1,
      perPage: 10,
      totalRows: 1,
      newKnowledgeBase: {
        area: null,
        knowledge: null,
      },
      knowledgeBaseDataForm: {
        area: null,
        knowledge: null,
      },
      idKnowledgeBase: undefined,
      itemsKnowledgeBase: [],
      userListCompleted: [],
      itemsRoles: [],
      itemsRolesFilter: [],
      areaList: [],
      selectMode: 'single',
      searchValue: null,
      query: null,
    }
  },
  computed: {
    getKnowledgeBaseList() {
      return this.itemsKnowledgeBase
    },
  },
  created() {
    if (!this.$ability.can('aiopsuser')) {
      this.$router.push({ name: 'aiops-home' })
    }
    this.areaList = JSON.parse(sessionStorage.getItem('areaList'))
    this.dataKnowledgeBase()
    this.welcomeAssist()
  },
  methods: {
    dataKnowledgeBase() {
      store.dispatch('knowledgeBase/knowledgeBaseList')
        .then(response => {
          const data = response
          this.itemsKnowledgeBase = data
          loading(false)
          loadingProccessSpinner(false)
        })
        .catch(() => {
          loading(false)
          loadingProccessSpinner(false)
        })
    },
    addNewKnowledBase() {
      this.$refs.aiopsNewKnowledgeBase.validate().then(success => {
        loadingProccessSpinner(true)
        if (success) {
          const payloadKnowledgeBase = this.newKnowledgeBase
          console.log(payloadKnowledgeBase)
          store.dispatch('knowledgeBase/createKnowledgeBase', payloadKnowledgeBase)
            .then(() => {
              this.addEditMode = !this.addEditMode
              this.newKnowledgeBase = this.knowledgeBaseDataForm
              return this.dataKnowledgeBase()
            })
            .catch(e => {
              const errorData = e
              if (errorData?.status === 400 || errorData?.status === 409) {
                setMessage(this.$bvToast, 'warning', `${errorData.message}`)
                loadingProccessSpinner(false)
                loading(false)
                return
              }
              this.addEditMode = !this.addEditMode
              loadingProccessSpinner(false)
              loading(false)
            })
        } else {
          loadingProccessSpinner(false)
        }
      })
    },
    updateKnowledgeBase() {
      this.$refs.aiopsNewKnowledgeBase.validate().then(success => {
        loadingProccessSpinner(true)
        if (success) {
          const payloadKnowledgeBase = this.newKnowledgeBase
          store.dispatch('knowledgeBase/updateKnowledgeBase', { payloadKnowledgeBase, id: this.idKnowledgeBase })
            .then(() => {
              this.addEditMode = !this.addEditMode
              this.idKnowledgeBase = undefined
              this.newKnowledgeBase = this.knowledgeBaseDataForm
              return this.dataKnowledgeBase()
            })
            .catch(e => {
              const errorData = e
              if (errorData?.status === 400 || errorData?.status === 409) {
                setMessage(this.$bvToast, 'warning', `${errorData.message}`)
                loadingProccessSpinner(false)
                loading(false)
                return
              }
              this.addEditMode = !this.addEditMode
              loadingProccessSpinner(false)
              loading(false)
            })
        } else {
          loadingProccessSpinner(false)
        }
      })
    },
    deleteKnowledgeBase(item) {
      loadingProccessSpinner(true)
      this.idKnowledgeBase = item.idKnowledge
      store.dispatch('knowledgeBase/deleteKnowledgeBase', { id: this.idKnowledgeBase })
        .then(() => {
          this.idKnowledgeBase = undefined
          return this.dataKnowledgeBase()
        })
        .catch(e => {
          const errorData = e
          if (errorData?.status === 400 || errorData?.status === 409) {
            setMessage(this.$bvToast, 'warning', `${errorData.message}`)
            loadingProccessSpinner(false)
            loading(false)
            return
          }
          this.addEditMode = !this.addEditMode
          loadingProccessSpinner(false)
          loading(false)
        })
    },
    welcomeAssist() {
      store.dispatch('productAiops/showWelcomeAssist', false)
    },
    addKnowledgeBaseMode() {
      this.actionForUser = 'New'
      this.editMode = false
      this.knowledgeBaseDataForm = {
        area: null,
        knowledge: null,
      }
      this.newKnowledgeBase = this.knowledgeBaseDataForm
      this.addEditMode = !this.addEditMode
    },
    editKnowledBase(items) {
      this.idKnowledgeBase = items.idKnowledge
      this.editMode = true
      this.actionForUser = 'Edit'
      this.newKnowledgeBase.area = items.area
      this.newKnowledgeBase.knowledge = items.knowledge
      this.addEditMode = !this.addEditMode
    },
    filterKnowledgeBase() {
      loadingProccessSpinner(true)
      if (this.selectedRole && this.selectedStatus && this.searchValue) {
        this.query = `?groupId=${this.selectedRole}&enabled=${this.selectedStatus}&search=${this.searchValue}`
      } else if (this.selectedRole && this.selectedStatus && !this.searchValue) {
        this.query = `?groupId=${this.selectedRole}&enabled=${this.selectedStatus}`
      } else if (this.selectedRole && !this.selectedStatus && this.searchValue) {
        this.query = `?groupId=${this.selectedRole}&search=${this.searchValue}`
      } else if (!this.selectedRole && this.selectedStatus && this.searchValue) {
        this.query = `?enabled=${this.selectedStatus}&search=${this.searchValue}`
      } else if (this.selectedRole && !this.selectedStatus && !this.searchValue) {
        this.query = `?groupId=${this.selectedRole}`
      } else if (!this.selectedRole && this.selectedStatus && !this.searchValue) {
        this.query = `?enabled=${this.selectedStatus}`
      } else if (!this.selectedRole && !this.selectedStatus && this.searchValue) {
        this.query = `?search=${this.searchValue}`
      } else {
        this.query = null
      }
      store.dispatch('userRolesAiops/findUsers', this.query)
        .then(response => {
          const knowledgeList = response
          this.itemsKnowledgeBase = knowledgeList
          loading(false)
          loadingProccessSpinner(false)
        })
        .catch(() => {
          loading(false)
          loadingProccessSpinner(false)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
  .backGroundHead {
    background-color: #2f2b6d;
  }

::v-deep {

  .b-table-sticky-header > .table.b-table > tbody.colorBody > tr > td {
    padding: 0.72rem 5px !important;
  }

  .colorHead {
    background-color: #eef7f9;
    color: #005573;
    font-size: 0.77vw;
    font-family: "Open Sans", Helvetica, Arial, serif;
  }

  .colorBody {
    background-color: #fff;
    color: #6f9ca8;
    font-size: 0.77vw;
    font-family: "Open Sans", Helvetica, Arial, serif;
    overflow-y: scroll !important;
    padding: 0.72rem 5px !important;
  }

  b-pagination .page-item .active .page-link {
    border-radius: 5rem;
    background-color: #12a71e !important;
  }

  .table tbody tr {
    height: 55px !important;
  }
}

.pagination {
  ::v-deep {
    .page-item.active {
      background-color: transparent;
      border-radius: 0;
    }
    .page-item.active .page-link {
      background: #ea5455 !important;
    }
    .page-link {
      background-color: transparent !important;
      color: #6f9ca8;
      font-weight: 200;
    }
    .page-link:hover {
      background-color: transparent !important;
      color: #6f9ca8;
    }
  }
}

.aiops_model_table_col_action {
  text-align: right;
}

.aiops__users--button-actions {
  display: flex;
  justify-content: space-evenly;
}

.aiops__users--button-actions-add-role {
  display: flex;
  justify-content: flex-end;
}

.aiops__roles-role-name {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 48px;
  height: auto;
}
</style>
